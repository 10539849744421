import { ref } from "vue";
import { projectFirestore } from "../../../../../../firebase/config";

const suppliers = ref([])

/* suppliers */
const get_suppliers_from_db = async() => {
    try{
        const docs = await projectFirestore.collection("Applications")
        .doc('DWwXD62stBclxW1krpZ9').collection('Suppliers').get()
        suppliers.value =  docs.docs.map(doc => doc.data())
    }catch(err){
        throw new Error(err)
    }
}

const delete_supplier_from_db_and_local = async(s) => {
    try{
        const index = suppliers.value.findIndex(({uid}) => uid == s.uid)
        if(index != -1){
            await projectFirestore.collection("Applications")
            .doc('DWwXD62stBclxW1krpZ9').collection('Suppliers').doc(s.uid).delete()
            suppliers.value.splice(index,1)
        }
    }catch(err){
        throw new Error(err)
    }
}

const edit_supplier_in_db = async(supplier) => {
    try {
        const index = suppliers.value.findIndex(({uid}) => uid == supplier.uid)
        if(index != -1){
            suppliers.value[index] = supplier
            await projectFirestore.collection("Applications")
                .doc('DWwXD62stBclxW1krpZ9').collection('Suppliers').doc(supplier.uid)
                .set(supplier,{merge:true})
        }
    } catch(err) {
        throw new Error(err.message);
    }
}


const add_supplier_in_db = async(supplier) => {
    try {
        // Reference to the Suppliers collection
        const suppliersCollection = projectFirestore.collection("Applications")
            .doc('DWwXD62stBclxW1krpZ9').collection('Suppliers');
        
        // Query to check if supplier.num already exists
        const existingSupplierNumQuery = await suppliersCollection
            .where('id', '==', supplier.id)
            .get();

        // Query to check if supplier.name already exists
        const existingSupplierNameQuery = await suppliersCollection
            .where('name', '==', supplier.name)
            .get();

        // Check if either query returned any documents
        if (!existingSupplierNumQuery.empty || !existingSupplierNameQuery.empty) {
            // Either supplier.num or supplier.name already exists in the database
            console.log('Supplier number or name already exists in the database.');
            if(!existingSupplierNumQuery.empty) throw new Error(`מספר ספק: ${supplier.id} כבר קיים במערכת!`); 
            throw new Error(`שם ספק: ${supplier.name} כבר קיים במערכת!`); 
        }

        
        
        const doc = suppliersCollection.doc();
        const data_obj = {
            uid: doc.id,
            name: supplier.name,
            id: supplier.id
        }
        await doc.set(data_obj);

        suppliers.value.unshift(data_obj)
        console.log('Supplier added successfully.');

    } catch(err) {
        throw new Error(err.message); // Make sure to throw a more specific error message
    }
}



export{
    suppliers,
    delete_supplier_from_db_and_local,
    get_suppliers_from_db,
    edit_supplier_in_db,
    add_supplier_in_db,
}